<script lang="ts" setup>
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { defaultManager } from '~/constants/content'
import { INFO_EMAIL } from '~/constants/common'

const props = defineProps<{
  name?: string
  phone?: string
  email?: string
  managerPhoto?: string
}>()

const responsive = useResponsive()

const phoneComponent = computed(
  () => {
    const tag = responsive.value.mobile ? 'a' : 'span'
    return {
      tag,
      attributes: tag === 'a'
        ? {
            href: `tel:${(props.phone || defaultManager.phone).replace(/ /g, '')}`,
            class: 'text-brand underline',
          }
        : {},
    }
  },
)
</script>

<template>
  <div class="flex flex-wrap gap-y-2">
    <div class="size-[100px] shrink-0 rounded-full border border-grey-stroke">
      <NuxtImg
        alt="managerName"
        class="size-full rounded-full object-cover"
        fit="inside"
        format="webp"
        height="300"
        loading="lazy"
        quality="100"
        :src="props.managerPhoto || '/manager-photo.jpg'"
        width="300"
      />
    </div>

    <div class="flex grow flex-col justify-center pl-4">
      <CfgTypography
        class="!font-highlighted"
        :size="CfgTypographySizes.md"
        tag-name="p"
      >
        {{ name || defaultManager.name }}
      </CfgTypography>

      <CfgTypography
        :size="CfgTypographySizes.md"
        tag-name="p"
      >
        {{ $t('contact.card.info.telephone') }}: <Component
          :is="phoneComponent.tag"
          v-bind="phoneComponent.attributes"
        >
          {{ phone || defaultManager.phone }}
        </Component>

        <br>
        {{ $t('contact.card.info.openningHours') }}
      </CfgTypography>

      <CfgTypography
        class="!leading-6"
        :size="CfgTypographySizes.md"
        tag-name="p"
      >
        {{ $t('contact.card.info.email') }}

        <a
          :href="`mailto:${email || INFO_EMAIL}`"
          target="_blank"
        >
          <span class="text-brand underline">{{ email || INFO_EMAIL }}</span>
        </a>
      </CfgTypography>
    </div>
  </div>
</template>
